<template>
<div>
    <div class='doc' v-if="!isLoading">
        <div class='flex'>
    <p>Заявление-анкета заемщика</p>
    <p>№00{{dg.DemandID}}</p>
    </div>
    <table id="t1">
        <tr>
            <td ><b>1. ФИО (полностью)</b> {{dg.ClLastName}} {{dg.ClFirstName}} {{dg.ClMiddleName}}</td>

        </tr>
        <tr>
            <td><b>2. Дата рождения: {{dg.ClBirthDate | timeFromXtoDateView}}</b></td>
        </tr>
        <tr>
            <td ><b>3. Паспорт </b> серия {{dg.PassportSeria}} номер {{dg.PassportNumber}} код подр.
                {{dg.PassportDepIssueCode}} дата выдачи {{dg.PassportDate}}</td>
        </tr>
        <tr>
            <td ><b>4.ИНН</b> {{dg.INN}}</td>
        </tr>
        <tr>
            <td><b>5. Моб.тел</b> +7{{dg.Phone}}</td>
        </tr>
        <tr>
            <td><b>6. Электронная почта</b> {{dg.EMail}} <input type="checkbox"  disabled /> нет</td>
        </tr>
        <tr>
            <td><b>7. Адрес регистрации: </b> {{dg.RegAddress}}</td>
        </tr>
        <tr>
            <td><b>8. Адрес проживания: </b> {{dg.FactAddress}}</td>
        </tr>
      <tr>
        <td><b>9. Место рождения: </b> {{dg.BirthPlace}}</td>
      </tr>
        <tr>
            <td>
                <b>10. Основное место работы: </b>{{dg.OrganizationName}}
            </td>
        </tr>
        <tr>
            <td><b>10.1 Стаж на данном месте работы: </b> {{dg.ExperienceName}}</td>
        </tr>
         <tr>
            <td><b>11. Сумма дохода в месяц: </b> {{dg.income}} руб</td>
        </tr>
        <tr>
            <td><b>12. Источники дохода:</b> {{dg.SOURCE_OF_INCOME}}</td>
        </tr>
        <tr>
            <td><b>13. Средние расходы в месяц:</b> {{dg.AVERAGE_EXPENSES_PER_MONTH}}  руб</td>
        </tr>
        <tr>
            <td><b>14. Средние расходы в месяц на платежи по кредитам (руб)</b>
                {{dg.AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS}}  руб</td>
        </tr>
        <tr>
            <td><b>15. Финансовое положение:</b> {{dg.FINANCIAL_POSITION}}</td>
        </tr>
        <tr>
            <td><b>16. Цель получения займа:</b> {{dg.PURPOSE_OF_THE_LOAN}}</td>
        </tr>
        <tr>
            <td><b>17.</b> <input type="checkbox"  disabled checked/>Подтверждаю, что действую к собственной выгоде.
                Иное
                лицо, к выгоде которого я действую (выгодоприобретатель) отсутствует. <br>
В случае, если Вы действуете к выгоде другого лица, необходимо заполнить анкету выгодоприобретателя.
</td>
        </tr>
        <tr>
            <td><b>18.</b> <input type="checkbox"  disabled checked/>Подтверждаю, что действую от своего имени, за
                свой счет и в своих интересах. Лица, прямо или косвенно контролирующие мои действия, в т.ч. имеющие возможность определять решения, принимаемые мной, отсутствуют.<br>
В случае, если Ваши действия контролируются, необходимо заполнить анкету бенефициарного владельца.<br>
В случае, если Вы представляете интересы другого лица, необходимо заполнить анкету представителя клиента.
</td>
        </tr>
        <tr>
            <td><b>19. Являетесь ли Вы лицом либо родственником лица из перечисленных ниже: </b>  (выбрать из
                перечисленного)<br>
                <input type="checkbox"  disabled checked/>Не являюсь<br/>
<input type="checkbox"  disabled /> Лицом, на которое возложено или было возложено ранее (с момента сложения полномочий прошло менее 1 года) исполнение важных государственных функций, в частности: Главой государства (в том числе правящей королевской династии) или правительства; Министром, его заместителем или помощником; Высшим правительственным чиновником; Должностным лицом судебных органов власти (Верховный, Конституционный суд); Государственным прокурором и его заместителем; Высшим военным чиновником; Руководителем или членом Совета директоров Национальных Банков; Послом; Руководителем государственных корпораций; Членом Парламента или иного законодательного органа.<br>
<input type="checkbox"  disabled />Лицом, облеченным общественным доверием, в частности: Руководителем, заместителем руководителя международных организаций (ООН, ОЭСР, ОПЕК, Олимпийский комитет, Всемирный Банк и т.д.); Членом Европарламента; Руководителем или членом международных судебных организаций (Суд по правам человека, Гаагский трибунал и др.).<br>
<input type="checkbox"  disabled /> Должностным лицом публичной международной организации, т.е. лицом, уполномоченным такой организацией действовать от ее имени.<br>
<input type="checkbox"  disabled /> Лицом, замещающим (занимающим) одну из перечисленных должностей: государственную должность Российской Федерации; должность члена Совета директоров Центрального банка Российской Федерации; должность федеральной государственной службы, назначение на которую и освобождение от которой осуществляются Президентом Российской Федерации или Правительством Российской Федерации; должность в Центральном банке Российской Федерации; должность в государственной корпорации; должности в иных организациях, созданных Российской Федерацией на основании федеральных законов, включенные в перечни должностей, определяемые Президентом Российской Федерации.<br>
<input type="checkbox"  disabled /> Родственником вышеуказанной категории лиц, в частности: Супругом; Родственником по прямой восходящей и нисходящей линии (родителями и детьми, дедушкой, бабушкой и внуками); Полнородным или не полнородным (имеющими общих отца или мать) братом или сестрой, усыновителем или усыновленным;<br>
 Указать степень родства: _____________________________________________________
</td>
        </tr>



    </table>
   <p> Я подтверждаю свое согласие на обработку в соответствии с ФЗ «О персональных данных» любых имеющихся в распоряжении ООО МКК «ФИНМОЛЛ» моих персональных данных (в том числе: ФИО, адрес, паспортные данные), а также информации обо мне, о моих заемных обязательствах перед ООО МКК «ФИНМОЛЛ» и об их исполнении, а также на передачу такой информации третьим лицам, в т.ч. в Бюро кредитных историй, и получение информации и документов от третьих лиц для осуществления проверки достоверности и полноты информации обо мне и в случаях, установленных законодательством.<br>
На запрос информации из Бюро кредитных историй и обработку моих персональных данных в целях возможного предоставления мне займа и подготовки кредитного отчета согласен.<br>
Я согласен на получение рекламных материалов (в том числе посредством мобильной телефонной связи и электронной почты), касающихся предоставления микрозаймов как ООО МКК «ФИНМОЛЛ», так и третьими лицами.<br>
Настоящим выступая как оператор персональных данных указанных в анкете контактных лиц, поручаю ООО МКК «ФИНМОЛЛ» обрабатывать их персональные данные, в целях проверки корректности предоставленных мной сведений, принятия решения о выдаче займа, предоставления дополнительной информации обо мне и совершения иных действий, в т. ч. проведения опросов или рекламно-информационных мероприятий. <br>
Информация, представленная мною в настоящем заявлении-анкете, является полной, точной и достоверной. <br>
Я предупрежден о последствиях предоставления мною заведомо ложных сведений о месте работы и уровне дохода.
</p>
        <signature
          :LastName="dg.ClLastName"
          :FirstName="dg.ClFirstName"
          :MiddleName="dg.ClMiddleName"
          :DemandDate="dg.DemandDate"
          :DataConfirm="dg.DataConfirm"
        ></signature>
<p>Основываясь на предоставленной мною информации, прошу предоставить мне заем на следующих условиях:</p>
<p> Cумма займа: {{dg.Amount }} рублей</p>
<p>Срок займа: {{dg.Period}} <span v-if="dg.Period == 30">дней</span><span v-else>недели</span></p>
<p>Я подтверждаю ознакомление и согласие с Правилами предоставления микрозаймов и Общими условиями договора займа с ООО МКК «ФИНМОЛЛ».</p>
        <signature
          :LastName="dg.ClLastName"
          :FirstName="dg.ClFirstName"
          :MiddleName="dg.ClMiddleName"
          :DemandDate="dg.DemandDate"
          :DataConfirm="dg.DataConfirm"
        ></signature>

<!--        <div v-if="dg.isBox == 'TRUE'">-->
<!--            <h2>ИНФОРМАЦИЯ ОБ УСЛУГАХ ТРЕТЬИХ ЛИЦ</h2>-->
<!--            <p><i>Заполняется при согласии на получение услуг третьих лиц</i></p>-->
<!--            <b><input type="checkbox" checked disabled /> «СТРАХОВАНИЕ ОТ ПОТЕРИ ТРУДОСПОСОБНОСТИ»</b>-->
<!--            <p> Страховое акционерное общество «ВСК» ОГРН 1027700186062<br>-->
<!--                По следующим реквизитам:<br>-->
<!--                Страховое акционерное общество «ВСК» ИНН 7710026574 ОГРН 1027700186062 р/с40701810600020001241 в ПАО «Сбербанк России», г. Москва к/с 30101810400000000225 БИК:044525225<br>-->
<!--                В сумме {{dg.TotalBoxSum}} руб.</p>-->
<!--&lt;!&ndash;            <p>В случае, если в таблице «Информация об услугах третьих лиц» мною проставлена отметка рядом с услугой, прошу перечислить часть займа в размере, составляющем стоимость дополнительной услуги, третьему лицу, предоставляющему соответствующую услугу. <br>&ndash;&gt;-->
<!--&lt;!&ndash;                Я подтверждаю, что правила оказания услуг третьими лицами мне разъяснены и мною получены. Я уведомлен о том, что правила оказания третьими лицами услуг доступны в Личном кабинете заемщика.<br>&ndash;&gt;-->
<!--&lt;!&ndash;                Я уведомлен о том, что приобретение услуг третьего лица не влияет на решение кредитора о предоставлении мне займа и на его условия.&ndash;&gt;-->
<!--&lt;!&ndash;            </p>&ndash;&gt;-->
<!--        </div>-->
<!--        <section class="section" v-if="dg.isBox === 'TRUE'">-->
<!--            <p><strong>РАЗДЕЛ 3.1 ОПЛАТА ТОВАРОВ/УСЛУГ ТРЕТЬЕГО ЛИЦА </strong></p>-->
<!--            <div><input type="checkbox" checked disabled>Прошу перечислить часть займа в размере {{dg.TotalBoxSum}} рублей, в-->
<!--                соответствии-->
<!--                с Заявлением-анкетой заемщика.</div>-->

<!--            <ol>-->
<!--                <li class="brDiv" v-if="EnabledBox_4">-->
<!--                    <b>«СТРАХОВАНИЕ ОТ ПОТЕРИ ТРУДОСПОСОБНОСТИ»</b>-->
<!--                    <p>Страховое акционерное общество «ВСК» ОГРН 1027700186062</p>-->
<!--                    <p>-->
<!--                        <a href="https://www.vsk.ru/upload/cache/default/tree/12/1109/tabs/Parvila_195_20200703.pdf" target="_blank">Порядок-->
<!--                            оказания информационных услуг https://www.vsk.ru</a>-->
<!--                    </p>-->
<!--                    <p>По следующим реквизитам: Страховое акционерное общество «ВСК» ИНН 7710026574 ОГРН 1027700186062-->
<!--                        р/с40701810600020001241 в ПАО «Сбербанк России», г. Москва к/с 30101810400000000225</p>-->
<!--                    <p>БИК:044525225</p>-->
<!--                    <p>В сумме 3000 руб. 00 коп</p>-->
<!--                </li>-->
<!--                <li class="brDiv" v-if="EnabledBox_1">-->
<!--                    <b>«ЛИЧНЫЙ АДВОКАТ»</b>-->
<!--                    <p>ООО «НЮС» ОГРН 1107746913064</p>-->
<!--                    <p>По следующим реквизитам:</p>-->
<!--                    <p>ООО «НЮС» ИНН 7702745920 ОГРН 1107746913064 р/с 40702810838170016009 в ПАО Сбербанк г.</p>-->
<!--                    <p>Москвы К/с: 30101810400000000225 БИК: 044525225</p>-->
<!--                    <p>В сумме 3000 руб. 00 коп.</p>-->
<!--                </li>-->
<!--                <li class="brDiv" v-if="EnabledBox_2">-->
<!--                    <b>«ТЕЛЕМЕДИЦИНА»</b>-->
<!--                    <p>ООО «МК Доктор рядом» ОГРН 1157746867464</p>-->
<!--                    <p>По следующим реквизитам:</p>-->
<!--                    <p>ООО «МК Доктор рядом» ИНН 7736253130 ОГРН 1157746867464 р/с 40702810738000057474 в</p>-->
<!--                    <p>ПАО «СБЕРБАНК РОССИИ» г. Москва К/с: 30101810400000000225 БИК: 044525225</p>-->
<!--                    <p>В сумме 3000 руб. 00 коп.</p>-->
<!--                </li>-->
<!--                <li class="brDiv" v-if="EnabledBox_3">-->
<!--                    <b>«ЗАБОТА»</b>-->
<!--                    <p>Порядок оказания информационных услуг <a href="https://zabota.online/fm-agreement">https://zabota.online/fm-agreement</a></p>-->
<!--                    <p>ООО «Консилиум М.Д. 24» ОГРН 1187746772124</p>-->
<!--                    <p>По следующим реквизитам:</p>-->
<!--                    <p>ООО «Консилиум М.Д. 24» ИНН 7725496540 ОГРН 1187746772124 р/с 40702810602080002939 в</p>-->
<!--                    <p>АО «АЛЬФА-БАНК» г. Москва К/с: 30101810200000000593 БИК: 044525593</p>-->
<!--                    <p>В сумме 4000 руб. 00 коп.</p>-->
<!--                </li>-->
<!--            </ol>-->


<!--            <signature-->
<!--              :LastName="dg.ClLastName"-->
<!--              :FirstName="dg.ClFirstName"-->
<!--              :MiddleName="dg.ClMiddleName"-->
<!--              :DemandDate="dg.DemandDate"-->
<!--              :DataConfirm="dg.DataConfirm"-->
<!--            ></signature>-->
<!--        </section>-->


    </div>
    <loader v-else></loader>
</div>
</template>
<script>
  function num_letters(k, d) {  // целое число прописью, это основа
    var i = '', e = [
      ['','тысяч','миллион','миллиард','триллион','квадриллион','квинтиллион','секстиллион','септиллион','октиллион','нониллион','дециллион'],
      ['а','и',''],
      ['','а','ов']
    ];
    if (k == '' || k == '0') return ' ноль'; // 0
    k = k.split(/(?=(?:\d{3})+$)/);  // разбить число в массив с трёхзначными числами
    if (k[0].length == 1) k[0] = '00'+k[0];
    if (k[0].length == 2) k[0] = '0'+k[0];
    for (var j = (k.length - 1); j >= 0; j--) {  // соединить трёхзначные числа в одно число, добавив названия разрядов с окончаниями
      if (k[j] != '000') {
        i = (((d && j == (k.length - 1)) || j == (k.length - 2)) && (k[j][2] == '1' || k[j][2] == '2') ? t(k[j],1) : t(k[j])) + declOfNum(k[j], e[0][k.length - 1 - j], (j == (k.length - 2) ? e[1] : e[2])) + i;
      }
    }
    function t(k, d) {  // преобразовать трёхзначные числа
      var e = [
        ['',' один',' два',' три',' четыре',' пять',' шесть',' семь',' восемь',' девять'],
        [' десять',' одиннадцать',' двенадцать',' тринадцать',' четырнадцать',' пятнадцать',' шестнадцать',' семнадцать',' восемнадцать',' девятнадцать'],
        ['','',' двадцать',' тридцать',' сорок',' пятьдесят',' шестьдесят',' семьдесят',' восемьдесят',' девяносто'],
        ['',' сто',' двести',' триста',' четыреста',' пятьсот',' шестьсот',' семьсот',' восемьсот',' девятьсот'],
        ['',' одна',' две']
      ];
      return e[3][k[0]] + (k[1] == 1 ? e[1][k[2]] : e[2][k[1]] + (d ? e[4][k[2]] : e[0][k[2]]));
    }
    return i;
  }
  function declOfNum(n, t, o) {  // склонение именительных рядом с числительным: число (typeof = string), корень (не пустой), окончание
    var k = [2,0,1,1,1,2,2,2,2,2];
    return (t == '' ? '' : ' ' + t + (n[n.length-2] == "1"?o[2]:o[k[n[n.length-1]]]));
  }
  function razUp(e) {  // сделать первую букву заглавной и убрать лишний первый пробел
    return e[1].toUpperCase() + e.substring(2);
  }
  function sum_letters(a) {
    a = Number(a).toFixed(2).split('.');  // округлить до сотых и сделать массив двух чисел: до точки и после неё
    console.log(typeof  a[0]);
    let out = num_letters(a[0]) + declOfNum(a[0], 'рубл', ['ь','я','ей']);
    if (a[1] != undefined && a[1].length > 0) {
      out += num_letters(a[1]) + declOfNum(a[1], 'копе', ['йка','йки','ек']);
    }
    return razUp(out);
  }

  import { getDocLoanForm } from '@/api/docGeter';
export default {
    name: "pdlForm",
    data() {
      return {
        isLoading: true,
        dg:{
          ClLastName:'',
          ClFirstName:'',
          ClMiddleName:'',
          DlProductTypeName:'',
          PassportSeria:'',
          PassportNumber:'',
          PassportDepIssueCode:'',
          FactAddress:'',
          RegAddress:'',
          EMail:'',
          Phone:'',
          SNILS:'',
          BIK:'',
          AccountNumber:'',
          ClFullName:'',
          PercentageRate:'0',
          Amount:'',
          CalcRate:'',
          OutPayments:'0',
          DemandID:'',
          isBox:'',
          TotalBoxSum:'',
          DemandDate:'',
          BankName:'',
          IsTopUp:false,
          PassportDepIssue:'',
          PreTopUp_DlCode:'',
          PreTopUp_RepaySum:'',
          PreTopUp_DlDate:'',
          boxArr:[],
          Period:'',
          INN:'',
          OrganizationName:'',
          Experienceid:'',
          income:'',
            DataConfirm:'',
        },

      }
    },
    methods:{
      async getLoanInfoForPdl(session_id,token){
        const requestResult = await getDocLoanForm(session_id,token);
        this.dg = {
          ...this.dg,
          ...requestResult.result
        }
        this.isLoading = false;
      }
    },
    components: {
        loader: () => import('@/components/other/loader'),
        signature: () => import('@/components/other/signature'),
    },
    mounted() {
      let params = this.$route.query;
      if (params.request != undefined) {
          this.isLoading = true;
        this.$external.universalRequest({
          externalType: 'zita',
          action:'getDealIU',
          DemandGuid:params.request
        }).then((res)=>{
          this.dg = res;
          if(res.isPdl === 1) {
            this.getLoanInfoForPdl(res.session_id,res.token);
          } else {
            this.isLoading = false;
          }
        }).catch((res)=>{
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        })
      }
    },
    computed:{
        EnabledBox_1(){
            let out = false;
            this.dg.boxArr.forEach((item)=>{
                if (item.Id === 1) {
                    out = ( item.IsEnabled === 1 );
                }
            });
            return out;
        },
        EnabledBox_2(){
            let out = false;
            this.dg.boxArr.forEach((item)=>{
                if (item.Id === 2) {
                    out = ( item.IsEnabled === 1 );
                }
            });
            return out;
        },
        EnabledBox_3(){
            let out = false;
            this.dg.boxArr.forEach((item)=>{
                if (item.Id === 3) {
                    out = ( item.IsEnabled === 1 );
                }
            });
            return out;
        },
        EnabledBox_4(){
            let out = false;
            this.dg.boxArr.forEach((item)=>{
                if (item.Id === 4) {
                    out = ( item.IsEnabled === 1 );
                }
            });
            return out;
        },
        OutPaymentsLettr(){
        return sum_letters(this.dg.OutPayments);
      },
      PercentageRateLettr(){
        return razUp(num_letters(this.dg.PercentageRate.toString()));
      },
      dayDemand(){
        try {
          let demandData = this.dg.DemandDate.split('.');
          return demandData[0];
        } catch (e) {
          return '__';
        }
      },
      mounthDemand(){
        try {
          let demandData = this.dg.DemandDate.split('.');
          return demandData[1];
        } catch (e) {
          return '__';
        }
      },
      yearDemand(){
        try {
          let demandData = this.dg.DemandDate.split('.');
          return demandData[2];
        } catch (e) {
          return '__';
        }
      }
    }
}
</script>

<style scoped>
.doc {background: white;  padding: 60px;width: 700px; font-size: 15px; margin: auto;}
 #t1, #t2, #t3 {border-collapse: collapse;min-width: 600px;width: 100%;}
  #t1 tr {}
  #t1 tr td {border: 1px solid #0f5740;padding: 5px}
  #t3 tr td {border: 1px solid #0f5740;padding: 5px}
  #t2 > tr > td {border: 1px solid #0f5740;padding: 5px}
  #t1 tr td:nth-child(1) {width: 5%;text-align: start;vertical-align: top;}
  #t1 tr td:nth-child(2) {width: 40%;vertical-align: top;}
  #t1 tr td:nth-child(3) {width: 55%;}
  #t3 > tr:nth-child(4) > td:nth-child(2) {padding: 0}
  #t3 > tr:nth-child(4) > td:nth-child(2) > table{border-collapse: collapse;width: 100%;}
 .flex{display: flex;justify-content: space-between;}
 .costTable{text-align: end; width: 67%;}
 .inf > div:first-child{padding-bottom: 24px;}
 h2{font-size: 15px;}
 input{margin:0  5px}
</style>
